<template>
  <div v-if="budgetPlan && budgetPlan.budgetType !== 'LIFETIME'">
    <template v-if="view === 'form'">
      <div class="mb-5">
        <label class="mb-0">Budget Type</label>
        <div class="mad-lib-container">
          <mad-lib-select :value="sharedToggleState" :disabled="disableSharedBudget" @input="toggleSharing" :options="[{ value: false, text: 'Single' }, { value: true, text: 'Shared' }]"></mad-lib-select>
        </div>
        <small class="text-muted">Shared Budgets allow you to link multiple campaigns. Single budgets are linked to only one campaign.</small>
      </div>
      <div v-if="sharedToggleState && !noActiveChannelBudgets && budgetPlan" class="mb-5">
        <label class="mb-0">Shared Budget</label>
        <div class="mad-lib-container">
          <mad-lib-select :value="budgetPlan.budgetPlanId"
                          @input="changeSharedBudget($event)"
                          :options="budgetOptions"></mad-lib-select>
        </div>
        <small class="text-muted">Budgets are specific to a channel type.</small>
        <t-alert :show="disabledInfo && !isPopover" class="mt-4" :variant="nonCompatibleBiddingStrategy ? 'warning' : 'info'">{{disabledInfo}}</t-alert>
        <t-alert :show="sharedToggleState && campaignGroupWarning.length > 0" class="mt-4" variant="warning">{{campaignGroupWarning}}</t-alert>
      </div>
    </template>
    <template v-else-if="view === 'button'">
      <t-button :disabled="disableSharedBudget" variant="primary" @click="toggleSharing">{{sharedToggleState ? 'Use Single Budget' : 'Use Shared Budget'}}</t-button>
    </template>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { SharedBudgetUnsupportedBidStrategies } from '~/assets/js/constants'
import BudgetsMixin from '@/mixins/budgetsMixin'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'

export default {
  name: 'campaignBudgetSelector',
  components: { MadLibSelect },
  mixins: [BudgetsMixin],
  props: {
    value: { // campaignPlanDTO (needs to have a budgetPlan)
      type: Object,
      required: true
    },
    accountBudgets: {
      type: Array,
      required: true // list of other budgets under the campaign's accountPlan
    },
    formGroupOpts: {
      type: Object,
      default: () => ({})
    },
    isPopover: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'form'
    }
  },
  data () {
    return {
      isInitiallyShared: false,
      initialBudgetPlan: null,
      sharedToggleState: false,
      campaignGroupWarning: ''
    }
  },
  async mounted () {
    if (!this.$store.getters.skeletons.BudgetPlan) {
      await this.$store.dispatch('fetchSkeleton', 'BudgetPlan')
    }
  },
  watch: {
    budgetPlan: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.isInitiallyShared = this.value?.budgetPlan?.explicitlyShared || false
          this.sharedToggleState = this.isInitiallyShared
          this.initialBudgetPlan = {
            ...this.budgetPlan
          }
          if (this.validateCampaignGroup(this.budgetPlan, [this.value]).invalid.length > 0) { //  from budgetsMixin
            this.$setCompat(this, 'campaignGroupWarning', `This budget is assigned to the ${this.budgetPlan.campaignGroupName} campaign group.`)
          } else {
            this.$setCompat(this, 'campaignGroupWarning', '')
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    budgetPlan () {
      return this.value.budgetPlan
    },
    advertisingChannelId () {
      const chan = this.budgetPlan?.advertisingChannelId || this.value.advertisingChannelId || 1
      return parseInt(chan)
    },
    campaignGroupName () {
      return this.value.campaignGroupName || this.budgetPlan?.campaignGroupName || null
    },
    activeChannelBudgets () {
      return this.filterSharedChannelBudgets(this.accountBudgets, this.advertisingChannelId)
    },
    noActiveChannelBudgets () {
      return this.activeChannelBudgets.length === 0
    },
    nonCompatibleBiddingStrategy () {
      return SharedBudgetUnsupportedBidStrategies.includes(this.value?.biddingStrategy) && this.budgetPlan.explicitlyShared
    },
    disabledInfo () {
      if (this.nonCompatibleBiddingStrategy) {
        return 'This campaign\'s bidding strategy is not compatible with shared budgets. Switch to a portfolio level bid strategy with the same setting to use a shared budget.'
      } else if (this.noActiveChannelBudgets) {
        return 'There are no active shared budgets within this advertising channel.'
      }
      return false
    },
    disableSharedBudget () {
      if (this.budgetPlan.budgetType === 'LIFETIME') { // don't allow shared lifetime budgets
        return true
      }
      return !this.sharedToggleState && (this.noActiveChannelBudgets || this.nonCompatibleBiddingStrategy)
    },
    budgetOptions () {
      return this.activeChannelBudgets.map(b => ({ value: b.budgetPlanId, text: `${b.name} (${this.$filters.currency(b.budgetAmount)}) ${(b.campaignPlans || []).length} active, attached campaigns` }))
    }
  },
  methods: {
    toggleSharing (val) {
      this.sharedToggleState = val
      if (this.sharedToggleState) {
        this.$emit('input', {
          ...this.value,
          budgetPlan: this.activeChannelBudgets[0]
        })
      } else {
        this.$emit('input', {
          ...this.value,
          budgetPlan: this.findImplicitBudgetByCampaignName() || reactive(this.newBudgetPlan(0))
        })
      }
    },
    findImplicitBudgetByCampaignName () {
      // when switching a campaign from a shared budget to an implicit budget, determine if there's an existing implicit budget with no attached campaigns with a name that includes this campaign's name.
      // this will help with name collisions by finding a budget that is likely to be the one originally assigned to the campaign
      const closestBudgetMatch = this.accountBudgets.find(b =>
        !b.explicitlyShared &&
        b.name?.includes(this.value?.name) &&
        b.campaignPlans?.length === 0 &&
        b.status === 'ENABLED' &&
        (b.advertisingChannelId || 1) === this.advertisingChannelId)

      return closestBudgetMatch || null
    },
    newBudgetPlan (budgetAmount) {
      return {
        ...this.$store.getters.newSkeleton('BudgetPlan'),
        budgetPlanId: null,
        accountPlanId: this.value.campaignPlanId,
        budgetAmount: budgetAmount || 0,
        explicitlyShared: false,
        budgetType: 'MONTHLY',
        deliveryMethod: 'STANDARD',
        name: `Campaign Budget: [${this.value.name}]`,
        status: 'ENABLED'
      }
    },
    changeSharedBudget (budgetPlanId) {
      const sharedBudget = this.accountBudgets.find(b => b?.budgetPlanId === budgetPlanId)
      if (sharedBudget) {
        this.$emit('input', {
          ...this.value,
          budgetPlan: sharedBudget
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>

.vue-js-switch {
  margin-top: 4px;
  border-radius: 11px;
  background-color: darkgray;
}

.vue-js-switch.toggled {
  background-color: $primary;
}
</style>
